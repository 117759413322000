<template>
  <div class="page">
    <main>
      <manage-tab />

      <div class="mb-5">
        <vue-csv-import
          v-model="csvData"
          :map-fields="mapFields"
          :headers="true"
          :autoMatchFields="true"
          :autoMatchIgnoreCase="true"
          :canIgnore="true"
          loadBtnText="読み込む"
        >
        </vue-csv-import>
      </div>

      <div v-if="csvData.length > 0">
        <hr>
        <b-field grouped>
          <b-field label="All">
            <p class="control">
              <span class="button is-static">{{ csvData.length }} レコード</span>
            </p>
          </b-field>
          <b-field label="Start">
            <b-input v-model="startIndex" type="number" />
          </b-field>
          <b-field label="End">
            <b-input v-model="endIndex" type="number" />
          </b-field>
        </b-field>

        <div class="level is-mobile mt-5">
          <div class="level-left">
            <div class="level-item">
              <button class="button is-primary" @click="importData" :class="{ 'is-loading': isProcessing }">インポート</button>
            </div>
            <div class="level-item">
              <label class="checkbox pl-1">
                <input type="checkbox" v-model="override">
                存在する場合は上書きする
              </label>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item" v-if="index < count">
              {{ index }} / {{ count }} ({{ progress }}%)
            </div>
          </div>
        </div>

        <b-button @click="checkSingIn" :class="{ 'is-loading': isProcessing }">サインインチェック</b-button>

        <hr>

        <div class="mb-6">
          <p class="has-text-weight-bold">プレビュー:</p>
          <div class="notification">
            <b-table :data="filteredCsvData" :columns="tableColumns" />
          </div>
        </div>

        <div class="mb-5">
          <p class="has-text-weight-bold">RAWデータ:</p>
          <div class="notification">{{ filteredCsvData[0] }}</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { firebase, auth } from '@/main'
import { VueCsvImport } from 'vue-csv-import'
import ManageTab from '@/components/manage/tab'

export default {
  components: { VueCsvImport, ManageTab },
  data() {
    return {
      csvData: [],
      mapFields: {
        email: "メールアドレス",
        password: "パスワード",
        status: "ステータス"
      },
      tableColumns: [
        { field: "email", label: "メールアドレス" },
        { field: "password", label: "パスワード", width: "100" },
        { field: "status", label: "ステータス", width: "100" },
      ],
      startIndex: 1,
      endIndex: 100,
      override: false,
      count: 0,
      index: 0,
      progress: 0,
      isProcessing: false
    }
  },
  methods: {
    importData() {
      const createOrUpdateUser = firebase.functions().httpsCallable('createOrUpdateUser')

      this.count = this.filteredCsvData.length
      this.index = 0
      this.progress = 0
      this.isProcessing = true

      this.filteredCsvData.forEach((data) =>{
        const params = { email: data.email.trim(), emailVerified: true }

        // パスワード
        if (("password" in data) && data.password) {
          params.password = data.password.trim()
        }

        // ステータス
        if (("status" in data) && data.status) {
          params.disabled = !(data.status.trim().toLowerCase() == "true")
        }

        console.log(params)

        createOrUpdateUser({ params: params, override: this.override })
        .then(result => {
          console.log('Successfully', params, result.data)
        })
        .catch(error => {
          console.error('Error', params, error)
        })
        .finally(() => {
          this.index++
          this.progress = Math.round(this.index / this.count * 100)
          this.isProcessing = (this.index < this.count)
        })
      })
    },
    checkSingIn() {
      this.isProcessing = true

      this.filteredCsvData.forEach((user) => {
        auth.signInWithEmailAndPassword(user.email, user.password).then(() => {
          console.log("Pass: ", user.email)
        }).catch(error => {
          console.error("Error: ", user, error)
        })
      })

      this.isProcessing = false
    },
  },
  computed: {
    filteredCsvData() {
      if (this.startIndex && this.startIndex > 0 && this.endIndex && this.endIndex > 0) {
        return this.csvData.slice(this.startIndex - 1, this.endIndex)
      } else {
        return []
      }
    }
  }
}
</script>


<style lang="scss" scoped>
main {
  height: 100%;
  overflow-y: scroll;
  padding: 1.5rem 2.5rem;
}

::v-deep .vue-csv-uploader-part-one {
  .csv-import-file {
    margin-bottom: 0.5rem;
  }
}
</style>
